import React, { useMemo } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Title from '../../molecules/title/title';
import { getCommonOrganismData } from '../../utils/functions';
import { Wrapper } from '../../utils/components';
import withPreview from '../../utils/with-preview';
import classNames from 'classnames';
import './BlogList.scss';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import { Button } from '../button/Button';
import { Link } from '../link';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import ContenutoHtmlInner from '../../molecules/helpers-contenuto-html/ContenutoHtmlInner';

function BlogList({ data, considerMenuSpace }) {
  const {
    anchorLabel,
    backgroundColor,
    htmlTagWrapper,
    marginTop,
    title,
    titleHeadingTag,
    titleIconId,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const categoriesFromBlog = data?.blogCategories;

  const categories = [
    {
      name: 'Tutti',
      description: 'color=--verde-turchese-50p',
    },
    ...categoriesFromBlog,
  ];

  const blogPosts = data?.assetList;

  const postsChunckSize =
    data?.listConfig?.content?.listElementsChunckSize?.value;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryFilter = params.get('category') || 'Tutti';
  const page = Number(params.get('page') || 1);
  const tagTitle = data?.listConfig?.content?.tagAssetTitle
    ? data?.listConfig?.content?.tagAssetTitle?.value[0]
    : 'p';

  const blogPostsFiltered = useMemo(() => {
    return blogPosts?.filter((blogPost) =>
      blogPost?.liferayFields?.taxonomyCategoryBriefs.some((category) =>
        categoryFilter !== 'Tutti' ? category?.name === categoryFilter : true
      )
    );
  }, [blogPosts, categoryFilter]);

  const blogPostsVisible = useMemo(() => {
    return blogPostsFiltered?.slice(0, postsChunckSize * page);
  }, [blogPostsFiltered, page, postsChunckSize]);

  return (
    <Wrapper
      data-module-code='C-87'
      data-module-name='blog-list'
      tag={htmlTagWrapper}
      className={classNames(
        `blog-list wrapper wrapper--underflow ${marginTop}`,
        {
          'blog-list--colored-background': backgroundColor !== 'transparent',
        }
      )}
      style={{ '--bg': `var(--${backgroundColor})` }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      {(title || titleIconId) && (
        <Title
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
          tag={titleHeadingTag}
        >
          {title}
        </Title>
      )}
      {categories?.length && (
        <nav className='blog-list__categories'>
          {categories.map((category, index) => (
            <button
              key={index}
              className={classNames('blog-list__category', {
                'blog-list__category--active': categoryFilter === category.name,
              })}
              style={{
                '--bg-category': `var(${category?.description.split('=')[1]})`,
              }}
              onClick={() => {
                const newQsParams = new URLSearchParams(location.search);
                if (category?.name === 'Tutti') {
                  newQsParams.delete('category');
                } else {
                  newQsParams.set('category', category?.name);
                }
                newQsParams.set('page', 1);
                navigate('?' + newQsParams.toString());
              }}
            >
              {category?.name}
            </button>
          ))}
        </nav>
      )}
      {blogPostsVisible?.length && (
        <section className='blog-list__posts'>
          {blogPostsVisible?.map((blogPost, index) => (
            <Link
              key={index}
              to={blogPost?.slugExtPage ? blogPost.slugExtPage : blogPost?.link}
              className='blog-list__post'
            >
              {blogPost?.blogPost?.content?.imageDetailPost && (
                <GatsbyImage
                  image={getImage(
                    blogPost.blogPost.content.imageDetailPost.node
                      .gatsbyImageData
                  )}
                  alt={
                    blogPost.blogPost.content.imageDetailPost.value.title || ''
                  }
                  className='blog-list__image'
                ></GatsbyImage>
              )}
              <div className='blog-list__body'>
                {blogPost?.liferayFields?.taxonomyCategoryBriefs?.length && (
                  <ul className='blog-list__post-categories'>
                    {blogPost.liferayFields.taxonomyCategoryBriefs?.map(
                      (category, index) => (
                        <li
                          key={index}
                          className='blog-list__post-category'
                          style={{
                            '--bg-category': `var(${
                              category?.description.split('=')[1]
                            })`,
                          }}
                        >
                          {category?.name}
                        </li>
                      )
                    )}
                  </ul>
                )}
                {(blogPost?.cardDetails?.content?.cardTitle?.value ||
                  blogPost?.detailPageTitle?.value) && (
                  <Wrapper tag={tagTitle} className='blog-list__title'>
                    {blogPost.cardDetails.content.cardTitle.value ||
                      blogPost.detailPageTitle.value}
                  </Wrapper>
                )}
                {blogPost?.cardDetails?.content?.cardDesc?.value && (
                  <section className='box-editorial blog-list__description'>
                    <ContenutoHtmlInner
                      rawContent={blogPost.cardDetails.content.cardDesc.value}
                    />
                  </section>
                )}
              </div>
            </Link>
          ))}
          {
            <div className='blog-list__number-of-elements'>
              <span>
                {blogPostsVisible?.length + ' di ' + blogPostsFiltered?.length}
              </span>
            </div>
          }
          <div className='blog-list__button'>
            <Button
              type='tertiary'
              text={'Mostra di più'}
              onClick={() => {
                const newQsParams = new URLSearchParams(location.search);
                newQsParams.set('page', page + 1);
                navigate('?' + newQsParams.toString());
              }}
              disabled={blogPostsFiltered?.length <= blogPostsVisible?.length}
            ></Button>
          </div>
        </section>
      )}
    </Wrapper>
  );
}

export default withPreview(BlogList);
